import Fade from 'react-reveal/Fade';
import bannerImage from './../../assets/images/dancingchildren3.jpg';
import styles from './Homepage.module.scss';
import { useEffect, useState } from 'react';
// import InstagramFeed from '../InstagramFeed/InstagramFeed';
import { BlogProps } from '../blogs/Blogs';
import Blog from '../blogs/blog/Blog';
import { NavLink, Link } from "react-router-dom";
import axios from 'axios';
import { DataHelper } from "../../data/DataHelper";

export interface IHomepageProps {
    datahelper: DataHelper;
}
function Homepage(props: IHomepageProps) {
    // let emptyArr: any[] = [];
    // const [instaFeed, setInstafeed] = useState(emptyArr);
    const [blogs, setBlogs] = useState<BlogProps[]>([]);

    useEffect(() => {
        // const token = "IGQWRQWXFXRmZAqSG4wc3AwU2pmdnNxVGVtSFdrOUU5Q3pxUUNsb2VLOHhrTWJ5UDViS1pzdFFrYVhZAWVNDcVNYWnNHSy10dTVXTVBlUWRaWXBmU2x0a2J4N3JOcGtPWWItVDdReERVX0N3b1puZAEVWVXIydFItMTgZD";
        // const url = "https://graph.instagram.com/me/media?fields=caption,media_count,media_type,permalink,media_url&&access_token=" + token;

        // try {
        //     fetch(url).then(function (response) {
        //         return response.json();
        //     }).then(data => {
        //         setInstafeed(data.data);
        //     }).catch(oef => {
        //         setInstafeed(defaultFeed);
        //         console.error(oef);
        //     });
        // } catch (err) {
        //     setInstafeed(defaultFeed);
        //     console.error(err);
        // }

        axios.get('/blogs/').then(res => {
            let blogsTemp = props.datahelper.sortByDateStringDescending(res.data);
            let blogs = blogsTemp.length > 2 ? blogsTemp.slice(-2) : blogsTemp;
            setBlogs(blogs);
        });
    }, [props.datahelper]);
    return (

        <div className={styles.homepage}>
            <div style={{ position: "relative" }}>
                <img className={styles.bannerImg} alt="bannerImage" src={bannerImage} />
                <div className={styles.overlay}>
                    <Fade duration={1500} bottom>
                        <section className={styles.mainInfo}>
                            <h1>Dansclub Happy Dancer</h1>
                            <p>Leer dansen op een aangename en ontspannen manier in de gezelligste club van uren in de omtrek!</p>
                            <NavLink to="/cursussen" className={styles.mainButton}>Ontdek onze cursussen</NavLink>
                        </section>
                    </Fade>
                </div>
            </div>
            <section className={styles.instaFeedWrapper}>
                {/* <InstagramFeed feed={instaFeed} token={""} counter="6" /> */}
                <div style={{borderTop:"2px solid #ff0076"}}></div>
            </section>
            <section className={styles.lastNews}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <div className={styles.titleMarkup}>
                                <h3>Lees hier de laatste nieuwtjes over onze dansclub</h3>
                                <h2>Nieuws</h2>
                            </div>
                        </div>
                        {props.datahelper.sortByDateStringAscending(blogs).map((blog, i) => (
                            <div key={i} className="col-lg-6">
                                <div className={styles.blogWrapper}>
                                    <Blog blog={blog} />
                                    <div className={styles.gradient}></div>
                                    <div className={styles.blogHover}>
                                        <Link to={"/nieuws/" + blog.id}>MEER LEZEN</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-12">
                        <NavLink to="/nieuws" style={{ marginBottom: "5em" }} className={styles.readMore}>Alle nieuwsberichten</NavLink>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Homepage;